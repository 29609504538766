import React from 'react';
import Layout from '../components/app-layout';
import IconAsterisk from '../components/icons/asterisk';
import SEO from '../components/seo';
import { Typography } from '../constants/typography';

const NotFoundPage = () => (
  <Layout defaultHeaderColor="text-white" footerColor="bg-white">
    <SEO title="Page Not Found" />
    <div className="w-screen h-screen sm:responsive-h-screen tp:responsive-h-screen tl:responsive-h-screen bg-charcoal flex justify-center items-center">
      <div className="flex items-center">
        <IconAsterisk className="w-[8vh] max-w-[60px] mt-2 mr-4" pathClassName="text-yellow" />
        <h1 className={`${Typography.h3} text-white`}>Page not found</h1>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
